


<script>
  import appConfig from "@/app.config";
  import store from "@/state/store";
  var state = store._modules.root._children.auth.state;
  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
    },
    data() {
      return {
        notfication_settings: [],
        orders: [],
        user_details: [],
        categories: [],
        offers: [],
        rate: [],
        coupons: [],
        page: 1,
        id: 0,
        limit: 20,
        tot_pages: 0,
        state: state,
      };
    },
    methods: {
      get(page) {
        console.log(page);
        let id = this.$route.params.id;
        console.log(id);
        this.http.get("users/customers/details/" + id).then((res) => {
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.user_details = res.data.user_details;
          this.orders = res.data.orders;
          this.categories = res.data.cateogries;
          this.coupons = res.data.coupons;
          this.rate = res.data.rate;
          this.offers = res.data.offers;
          this.user_details = res.data.user_details;
        });
      },
    },
    created() {
      this.id = this.$route.params.id;
      this.get(1);
    },
  };
  </script>
  
  <template>
      <div class="row card">
        <b-tabs
          content-class="p-4"
          class="pt-2"
          nav-wrapper-class="nav-item"
          nav-class="justify-content-start nav-tabs-custom"
        >
          <!-- marketing channels -->
  
  
          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.orders.text')" active>
            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("orders.id") }}</th>
                  <th scope="col">{{ $t("orders.custmor_id") }}</th>
                  <th scope="col">{{ $t("orders.location") }}</th>
                  <th scope="col">{{ $t("orders.total") }}</th>
                  <th scope="col">{{ $t("orders.status_id") }}</th>
                  <th scope="col">{{ $t("orders.created") }}</th>
                  <th scope="col">{{ $t("orders.updated") }}</th>
                </tr>
              </thead>
              <tbody>
               <tr v-for="(app,index) in orders" :key="app" class="text-center">
            <td>
              <router-link :to="'/order-deatiles/' + app?.id">
                # {{ index+1 }}</router-link
              >
            </td>
            <td>
              <router-link :to="'/order-deatiles/' + app?.id">
                {{ app?.order?.user?.name }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/order-deatiles/' + app?.id">
                {{ app?.order?.user_address?.address }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/order-deatiles/' + app?.id">
                {{
               parseInt(app?.order_price) - 
  
                parseInt(app?.discount)+ 
                
                parseInt(app?.order?.tip)
                
                }}
              </router-link>
            </td>
            <td>
              <router-link :to="'/order-deatiles/' + app?.id">
                {{ app?.order?.status?.status_ar }}
              </router-link>
            </td>
            <td>{{ app?.order?.created.split("T")[0] }}</td>
            <td>{{ app?.order?.updated.split("T")[0] }}</td>
          </tr>
              </tbody>
            </table>
          </b-tab>
          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.rate.text')">
            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("orders.id") }}</th>
                  <th scope="col">{{ $t("popups.comment") }}</th>
                  <th scope="col">{{ $t("popups.points") }}</th>
                  <th scope="col">{{ $t("orders.user") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, i) in rate" :key="app" class="text-center">
                  <td>{{ i + 1 }}</td>
                  <td>{{ app?.comment }}</td>
                  <td>{{ app?.points }}</td>
                  <td>{{ app?.user.name }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>
  
          <!-- marketing channels -->
          <b-tab :title="$t('menu.menuitems.transactions.text')">
            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("transactions.id") }}</th>
                  <th scope="col">{{ $t("transactions.from") }}</th>
                  <th scope="col">{{ $t("transactions.date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, i) in coupons" :key="app" class="text-center">
                  <td>{{ i + 1 }}</td>
                  <td>{{ app?.from }}</td>
                  <td>{{ app?.date.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
      </div>
      <!--end-->
  </template>
  
  <style scoped>
  .btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
  }
  </style>
  
  
  
  
  