


<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
// import Details from "./profile_details.vue";
import Details from "./extra_profile_details.vue";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Details,
  },
  data() {
    return {
      notfication_settings: [],
      orders: [],
      user: [],
      roles: [],
      user_roles: [],
      rate: [],
      coupons: [],
      page: 1,
      id: 0,
      limit: 20,
      tot_pages: 0,
      state: state,
    };
  },
  methods: {
    changeStates(app) {
      var status = 0;
      if (app.status == 1) {
        status = 0;
      }
      if (app.status == 0) {
        status = 1;
      }
      let data = { id: this.id, status: status };
      this.http.post("users/update-status", data).then((res) => {
        if (res) {
          this.get(1);
        }
      });
    },
    deleteUsersRoles(app) {
      var data = {
        title: "",
        msg: "popups.delete_message",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("users-roles", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    get(page) {
      console.log(page);
      let id = this.$route.params.id;
      console.log(id);
      this.http.get("users/" + id).then((res) => {
        this.tot_pages = Math.ceil(res.tot / this.limit);
        this.user = res?.data.result;
        console.log(49, this.user);
        this.user_roles = res?.data?.roles;
        this.http.get("roles/" + this.id).then((info) => {
          if (info.status) {
            this.roles = info.data;
          }
        });
      });
    },

    addRole() {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "role_id",
            type: "select",
            options: (() => {
              return this?.roles?.map((data) => {
                return { value: data.id, label: data.role };
              });
            })(),
            label: "popups.role",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.user_id = this.id;
              this.http.post("users-roles", obj).then(() => {
                this.get(1);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="user?.name"
    />

    <div class="row mt-2">
      <div class="col">
        <div class="d-print-none">
          <div class="float-end">
            <a
              href="javascript:window.print()"
              class="btn btn-success waves-effect waves-light me-1 mx-2"
              ><i class="fa fa-print"></i
            ></a>
            <a
              v-if="user.status"
              href="#"
              @click="changeStates(user)"
              class="btn btn-primary w-md waves-effect waves-light mx-2"
              >{{ $t("popups.active") }}</a
            >
            <a
              v-if="!user.status"
              href="#"
              @click="changeStates(user)"
              class="btn btn-warning w-md waves-effect waves-light mx-2"
              >{{ $t("popups.enactive") }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary">{{ $t("login.welcome") }}</h5>
                  <p>{{ $t("login.title") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-4">
                <div class="avatar-md profile-user-wid mb-4">
                  <img
                    :src="
                      $store.state?.auth?.mediaUrl +
                      user?.image?.split('public')[1]
                    "
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 class="font-size-15 text-truncate">{{ user?.name }}</h5>
              </div>

              <div class="col-sm-8" style="display:none">
                <div class="pt-4">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="font-size-15">1,245</h5>
                      <p class="text-muted mb-0">{{ $t("account.revenue") }}</p>
                    </div>
                  </div>
                  <div class="mt-4" style="display:none">
                    <router-link to="/reports" class="btn btn-primary btn-sm">
                      {{ $t("account.go_reports") }}
                      <i class="mdi mdi-arrow-right ms-1"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t("account.personal") }}</h4>

            <p class="text-muted mb-4">{{ user?.descr }} .</p>
            <div class="table-responsive">
              <table class="table table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{{ $t("account.full") }} :</th>
                    <td>{{ user?.name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("account.phone") }} :</th>
                    <td>+249 115962947</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("account.email") }} :</th>
                    <td>fast@gmail.com</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("account.country") }} :</th>
                    <td>
                      {{
                        $i18n.locale == "ar" ? user?.c_name : user?.c_name_en
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("account.city") }} :</th>
                    <td>
                      {{
                        $i18n.locale == "ar" ? user?.co_name : user?.co_name_en
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t("account.location") }} :</th>
                    <td>
                      {{
                        $i18n.locale == "ar" ? user?.c_name : user?.c_name_en
                      }},
                      {{
                        $i18n.locale == "ar" ? user?.co_name : user?.co_name_en
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-8">
        <div class="row">
          <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
            <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
          </div>
        </div>
        <div class="card"></div>

        <div class="card" v-if="user?.user_type_id == 1">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <button type="button" class="btn btn-light float-end mb-4">
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    @click="addRole()"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <h4 class="card-title mb-4">{{ $t("popups.roles_management") }}</h4>
            <div class="table-responsive">
              <table class="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("popups.role") }}</th>
                    <th scope="col">{{ $t("popups.created") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(role, index) in user_roles" :key="role">
                    <td>{{ index + 1 }}</td>
                    <td>{{ role?.role.role }}</td>
                    <td class="text-sm-end">
                      {{ role?.role.created?.split("T")[0] }}
                    </td>
                    <td class="text-sm-end">
                      <a
                        class="btn btn-danger mx-1"
                        href="javascript: void(0);"
                        role="button"
                        @click="deleteUsersRoles(role)"
                        >{{ $t("popups.remove_role") }}</a
                      >ةش
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
                <Details/>

      </div>

    
    </div>

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




